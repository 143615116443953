import { combineReducers } from '@reduxjs/toolkit';

import { oauthSlice } from './oauth/oauthSlice';
import { vehiclesSlice } from './catalog/vehiclesSlice';
import { subscriptionsSlice } from './subscriptions/subscriptionsSlice';
import { usersSlice } from './users/usersSlice';
import { servicePointsSlice } from './servicePoints/servicePointsSlice';
import { insurancesSlice } from './insurances/insurancesSlice';
import { bookingsSlice } from './bookings/bookingsSlice';
import { roadsideAssistanceSlice } from './roadsideAssistance/roadsideAssistanceSlice';
import { serviceVehiclesSlice } from './serviceVehicles/serviceVehiclesSlice';
import { subscribersSlice } from './subscribers/subscribersSlice';
import { fleetVehiclesSlice } from './fleetVehicles/fleetVehiclesSlice';
import { fleetVehicleSlice } from './fleetVehicles/fleetVehicleSlice';
import { availabilitySlice } from './availability/availabilitySlice';
import { returnsInboxSlice } from './returnsInbox/returnsInboxSlice';
import { campaignsSlice } from './campaigns/campaignsSlice';
import { notificationsSlice } from './notifications/notificationsSlice';
import { handoverCarsSlice } from './handoverCars/handoverCarsSlice';
import { buyOutSlice } from './buyout/buyOutSlice';
import { layoutSlice } from './layout/layoutSlice';
import { arnmAccidentsSlice } from './arnmAccidents/arnmAccidentsSlice';
import { arnmRepairsSlice } from './arnmRepairs/arnmRepairsSlice';
import { arnmMaintenanceSlice } from './arnmMaintenance/arnmMaintenanceSlice';
/* PLOP_INJECT_SLICE_IMPORT */
import { ebikesSlice } from './ebike/ebikeSlice';
import { ordersSlice } from './orders/ordersSlice';
import { vendorsSlice } from './vendors/vendorsSlice';
import { addFleetVehicleSlice } from './fleetVehicles/addFleetVehicleSlice';
import { availabilityTableSlice } from './availabilityTable/availabilityTableSlice';
import { reservationsSlice } from './reservations/reservationsSlice';
import { greenCardSlice } from './greenCard/greenCardSlice';
import { inspectionCarsSlice } from './inspectionCars/inspectionCarsSlice';
import { trafficOffensesSlice } from './trafficOffenses/trafficOffensesSlice';
import { trafficOffensesTaskSlice } from './trafficOffenses/trafficOffensesTaskSlice';
import { sellVehiclesSlice } from './sellVehicles/sellVehiclesSlice';
import { sellRequestsSlice } from './sellRequests/sellRequestsSlice';
import { teamsSlice } from './teams/teamsSlice';
import { communicationSlice } from './communication/communicationSlice';

/* ui grid setting */
import { uiSettingsSlice } from './uiSettings/uiSettingsSlice';
import { mailBoxesSlice } from './mailBoxes/mailBoxesSlice';
import { driverseCampaignsSlice } from './driverseCampaigns/driverseCampaignsSlice';
import { driversePartnersSlice } from './driversePartners/driversePartnersSlice';

const rootReducer = combineReducers({
  [oauthSlice.name]: oauthSlice.reducer,
  [vehiclesSlice.name]: vehiclesSlice.reducer,
  [subscriptionsSlice.name]: subscriptionsSlice.reducer,
  [usersSlice.name]: usersSlice.reducer,
  [servicePointsSlice.name]: servicePointsSlice.reducer,
  [insurancesSlice.name]: insurancesSlice.reducer,
  [bookingsSlice.name]: bookingsSlice.reducer,
  [roadsideAssistanceSlice.name]: roadsideAssistanceSlice.reducer,
  [serviceVehiclesSlice.name]: serviceVehiclesSlice.reducer,
  [subscribersSlice.name]: subscribersSlice.reducer,
  [fleetVehiclesSlice.name]: fleetVehiclesSlice.reducer,
  [fleetVehicleSlice.name]: fleetVehicleSlice.reducer,
  [availabilitySlice.name]: availabilitySlice.reducer,
  [returnsInboxSlice.name]: returnsInboxSlice.reducer,
  [campaignsSlice.name]: campaignsSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [handoverCarsSlice.name]: handoverCarsSlice.reducer,
  [buyOutSlice.name]: buyOutSlice.reducer,
  [layoutSlice.name]: layoutSlice.reducer,
  [arnmAccidentsSlice.name]: arnmAccidentsSlice.reducer,
  [arnmRepairsSlice.name]: arnmRepairsSlice.reducer,
  [arnmMaintenanceSlice.name]: arnmMaintenanceSlice.reducer,
  /* PLOP_INJECT_SLICE_TO_STORE */
  [ebikesSlice.name]: ebikesSlice.reducer,
  [ordersSlice.name]: ordersSlice.reducer,
  [vendorsSlice.name]: vendorsSlice.reducer,
  [uiSettingsSlice.name]: uiSettingsSlice.reducer,
  [addFleetVehicleSlice.name]: addFleetVehicleSlice.reducer,
  [availabilityTableSlice.name]: availabilityTableSlice.reducer,
  [reservationsSlice.name]: reservationsSlice.reducer,
  [greenCardSlice.name]: greenCardSlice.reducer,
  [inspectionCarsSlice.name]: inspectionCarsSlice.reducer,
  [trafficOffensesSlice.name]: trafficOffensesSlice.reducer,
  [trafficOffensesTaskSlice.name]: trafficOffensesTaskSlice.reducer,
  [sellVehiclesSlice.name]: sellVehiclesSlice.reducer,
  [sellRequestsSlice.name]: sellRequestsSlice.reducer,
  [teamsSlice.name]: teamsSlice.reducer,
  [communicationSlice.name]: communicationSlice.reducer,
  [mailBoxesSlice.name]: mailBoxesSlice.reducer,
  [driverseCampaignsSlice.name]: driverseCampaignsSlice.reducer,
  [driversePartnersSlice.name]: driversePartnersSlice.reducer,
});

export default rootReducer;
