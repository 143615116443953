import { omit, pickBy } from 'lodash';

import { FILTER_OPERATOR_ENUMS, FILTER_RULE_ENUMS } from '@constants';
import { formatDate } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';

export const handleAgentsData = (data = []) => {
  if (!data) {
    return [];
  }

  return data?.map((item) => ({
    status: item.status || '-',
    createdAt: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : '-',
    updatedAt: item.updatedAt ? formatDate(item.updatedAt, 'DD/MM/YYYY') : '-',
    email: item?.email || '-',
    firstName: item?.firstName || '-',
    lastName: item?.lastName || '-',
    fullName: `${item?.firstName || ''} ${item?.lastName || ''}`,
    role: item?.role || '-',
    id: item.id,
    userName: item.username || '-',
  }));
};

export const getCleanedUrlAgentsParams = (params) => {
  return pickBy(
    params,
    (item) => item !== null && item !== undefined && item !== ''
  );
};

export const getAgentsUrl = (params) => {
  const urlParams = getCleanedUrlAgentsParams(params);
  const searchParams = new URLSearchParams(urlParams).toString();

  return `${URLS.agents}?${searchParams}`;
};

export const getAgentsRequestPayload = (payload = {}) => {
  const fields = omit(payload, ['page', 'pageSize', 'searchTerm']) || {};

  const { searchTerm } = payload;
  // if the selected reservationType is not the hardcoded "booked" type

  const value = [
    ...Object.keys(fields).map((field) => ({
      field,
      operator: FILTER_OPERATOR_ENUMS['='],
      type: FILTER_RULE_ENUMS.ComparableRuleType,
      value: payload[field],
    })),
  ];

  if (searchTerm) {
    const filedsToSearch = ['username', 'email'];

    const searchRule = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      value: filedsToSearch.map((field) => ({
        type: FILTER_RULE_ENUMS.CompareStringRuleType,
        operator: FILTER_OPERATOR_ENUMS.contains,
        field,
        value: searchTerm,
      })),
    };
    value.push(searchRule);
  }

  if (value.length) {
    return {
      type: FILTER_RULE_ENUMS.AndRuleType,
      field: null,
      operator: null,
      value,
    };
  }

  return {
    type: FILTER_RULE_ENUMS.EmptyRuleType,
  };
};
