import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';

import { agentsServices, boardsServices } from '@services';
import { BOARDS } from '@constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import {
  getBillingQueryParams,
  getBillingRequestPayload,
  getColumns,
  getUrl,
  normaliseAssigneeData,
  normaliseBillingTasks,
} from './utils';
import { BILLING_PAGE_SIZE, BILLING_TABS } from './constants';

export const useBillingOperations = () => {
  const router = useRouter();
  const page = router.query?.page || 1;
  const assignee = router.query?.assignee;
  const orderBy = router.query?.orderBy;
  const order = router.query?.order;
  const searchTerm = router.query?.searchTerm;
  const board = router.query?.board || Object.keys(BILLING_TABS)[0];

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  const params = {
    page,
    assignee,
    orderBy,
    order,
    board,
    searchTerm,
  };

  const cleanParams = getBillingQueryParams({
    ...params,
    boardType: BOARDS[`BILLING_${board.toLocaleUpperCase()}`],
  });
  const assigneesResponse = useQuery(QUERY_KEYS.GET_BILLING_ASSIGNEES_KEY, () =>
    agentsServices.getAgents({
      page: 1,
      pageSize: 3000,
    })
  );

  const billingResponse = useQuery(
    [QUERY_KEYS.GET_BILLING_KEY, cleanParams],
    () =>
      boardsServices.getBillingBoardsFilters({
        boardId: BILLING_TABS[board].type,
        params: getBillingQueryParams(cleanParams),
        payload: getBillingRequestPayload({
          ...cleanParams,
          boardId: BILLING_TABS[board].id,
          boardType: BILLING_TABS[board].type,
        }),
      }),
    { enabled: assigneesResponse.isFetched }
  );

  const handlePageChange = (_event, newPage) => {
    router.push(getUrl({ ...router.query, page: newPage }));
  };

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...router.query, ...sortByModel });

    router.push(url);
  };

  const billingTasks = normaliseBillingTasks(
    billingResponse?.data?.data || [],
    assigneesResponse?.data?.data || []
  );

  const statuses = BILLING_TABS[board].statusOrder.reduce((acc, cur) => {
    acc.push({
      text: BILLING_TABS[board].statuses[cur].label,
      value: Number(cur),
    });
    return acc;
  }, []);

  return {
    page,
    pageSize: BILLING_PAGE_SIZE,
    assignees: normaliseAssigneeData(assigneesResponse?.data?.data || []) || [],
    total: billingResponse?.data?.meta?.total,
    loading: billingResponse?.isLoading || assigneesResponse?.isLoading,
    error: billingResponse?.error,
    isSuccess: billingResponse?.isSuccess,
    billingTasks,
    board,
    statuses,
    columns: getColumns(billingTasks || [], board),
    handlePageChange,
    handleSortBy,
    refresh: billingResponse.refetch,
  };
};
