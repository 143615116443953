import { pickBy } from 'lodash';

import { formatDate } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';

export const getCleanedUrlParams = (params) =>
  pickBy(params, (item) => item !== null && item !== undefined && item !== '');

export const getUrl = (params) => {
  const urlParams = getCleanedUrlParams(params);
  const searchParams = new URLSearchParams(urlParams).toString();

  return `${URLS.partners}?${searchParams}`;
};
export const handlePartnersData = (data) => {
  if (!data) {
    return [];
  }

  return data?.map((item) => {
    const defaultPhoneNumber =
      item.phoneNumbers?.find((phone) => phone.isDefault) ||
      item.phoneNumbers?.[0];

    return {
      id: item.id,
      name: item.name,
      email: item.email,
      password: item.password,
      isActive: item.isActive,
      internalId: item.internalId,
      phoneNumbers: defaultPhoneNumber ? [defaultPhoneNumber] : [],
      createdAt: item.createdAt
        ? formatDate(item.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item.updatedAt
        ? formatDate(item.updatedAt, 'DD/MM/YYYY')
        : '-',
    };
  });
};
