import { pickBy } from 'lodash';

import { generateShortId } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';
import { ENTITIES_PERMISIONS_NAMES } from 'components/common/PermissionsGate/constants';

export const getCleanedUrlParams = (params) =>
  pickBy(params, (item) => item !== null && item !== undefined && item !== '');

export const getUrl = (params) => {
  const urlParams = getCleanedUrlParams(params);
  const searchParams = new URLSearchParams(urlParams).toString();

  return `${URLS.permissions}?${searchParams}`;
};

export const handlePermissionsData = (data) => {
  if (!data) {
    return [];
  }

  return data?.map((item) => ({
    id: item?.id || generateShortId(),
    role: item.role || '-',
    permission: ENTITIES_PERMISIONS_NAMES[item?.permission] || '-',
  }));
};
