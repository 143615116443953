import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { showError } from '@utils';
import { FILTER_RULE_ENUMS } from '@constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import { partnersServices } from '@services';
import { useQuery } from 'react-query';
import { getUrl, handlePartnersData } from './utils';

export const PARTERNS_PAGE_SIZE = 300;

export const usePartnersOperations = () => {
  const router = useRouter();
  const page = router.query?.page || 1;
  const { isReady } = router;

  const partnersResponse = useQuery(
    [QUERY_KEYS.GET_PARTNERS_KEY, page],
    () =>
      partnersServices.getPartners({
        params: { page, pageSize: PARTERNS_PAGE_SIZE },
        payload: { type: FILTER_RULE_ENUMS.EmptyRuleType },
      }),
    { enabled: isReady }
  );

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (partnersResponse?.error) {
      showError(partnersResponse?.error);
    }
  }, [partnersResponse?.error]);

  const handlePageChange = (_event, newPage) => {
    router.push(getUrl({ ...router.query, page: newPage }));
  };

  return {
    page,
    total: partnersResponse?.data?.meta?.total,
    loading: partnersResponse?.isLoading,
    partners: handlePartnersData(partnersResponse?.data?.data || []) || [],
    pageSize: PARTERNS_PAGE_SIZE,
    error: partnersResponse?.error,
    refetch: partnersResponse.refetch,
    handlePageChange,
  };
};
